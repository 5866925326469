import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Container, Grid, InputLabel, TextField, Typography, Button, linearProgressClasses, FormControl, Select, MenuItem, Snackbar, Alert, Backdrop, CircularProgress, Checkbox, Menu, Popover, Stack, Chip, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, LinearProgress, Box, Autocomplete } from '@mui/material';
import { TableData } from '@/components/common/Table';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_SOFTNET, KEY_TOKEN_SOFTNET, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { SpinnerGrow } from '@/components/common/Spinner';
import { useHistory } from 'react-router-dom'
import { ArriendosService } from '@/service/services/Arriendos.service.';
import { ModalGarantiaArriendo } from './ModalGarantiaArriendo/ModalGarantiaArriendo';
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import './productTable.css'
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import StorageIcon from '@mui/icons-material/Storage';

import { LiquidPorcentajeCumpliance } from './DashArriendos/LiquidPorcentajeCumpliance';
import { ExpirationBar } from './DashArriendos/ExpirationBar';
import { LineBarFacturadorDevengados } from './DashArriendos/LineBarFacturadosDevengados';
import { DoughnutEstadoGeneral } from './DashArriendos/DoughnutEstadoGeneral';
import { BarStatusProducts } from './DashArriendos/BarStatusProducts';
import { ConfirmDialog } from '@/components/common/DialogConfirm';
import { contactService } from '@/service/services/Contact.service';

const header = [
   { name: 'fecha_arriendo', label: 'Fecha Arriendo', width: '' },
   // { name: 'cod_pago_mensual', label: 'Codigo Pago', width: '' },
   { name: 'rut_cliente', label: 'RUT Cliente', width: '' },
   { name: 'nombre_rz', label: 'Cliente', width: '' },
   { name: 'rut_cliente_facturacion', label: 'RUT Clente Facturacion', width: '' },
   { name: 'nombre_rz_facturacion', label: 'Cliente Facturacion', width: '' },
   { name: 'total_cantidad_actual', label: 'N° cilindros', width: '' },
   { name: 'url_documento', label: 'Documento contrato', width: '', link: true },
   { name: 'observacion', label: 'Obs. Administrador', width: '' },
   { name: 'status_name', label: 'Estado', width: '' }
];

const headerProducts = [
   { name: 'codigo', label: 'Código', width: '' },
   { name: 'nombre', label: 'Producto', width: '' },
   { name: 'stock', label: 'Stock', width: '' },
   { name: 'disponibles', label: 'Disponibles', width: '' },
   { name: 'arrendados', label: 'Arrendados', width: '' },
]
const dataAction = [
   { name: 'view-detail', color: 'success' },
   { name: 'delete', color: 'error' },
]

// const dataProductosBusqueda = [
//    { id: 1, codigo: '(XLE-AP-4040)', nombre: 'Membrana Aguaplanet XLE-AP-4040 (2600GPD) 100PSI' },
//    { id: 2, codigo: '(XLE-AP-4080)', nombre: 'Membrana Aguaplanet XLE-AP-4080 (2800GPD) 100PSI' },
// ]

// const dataProducts = [
//    { id: 1, codigo: '(XLE-AP-4040)', nombre: 'Membrana Aguaplanet XLE-AP-4040 (2600GPD) 100PSI', stock: 10, disponibles: 8, arrendados: 2 },
//    { id: 2, codigo: '(XLE-AP-4080)', nombre: 'Membrana Aguaplanet XLE-AP-4080 (2800GPD) 100PSI', stock: 9, disponibles: 0, arrendados: 9 },
// ]

const dataStatus = [
   { id: 1, name: 'Vigente' },
   { id: 2, name: 'Histórico' }
]
const buttonFilterStyle = {
   backgroundColor: 'white',
   color: 'black',
   fontWeight: 700,
   border: '1px solid black',
   padding: '10px',
   display: 'flex',
   alignItems: 'center',
   margin: '0 8px',
   justifyContent: 'space-between',
   cursor: 'pointer',
};

const buttonFilterStyle1 = {
   backgroundColor: 'white',
   color: 'black',
   fontWeight: 700,
   padding: '10px',
   display: 'flex',
   alignItems: 'center',
   margin: '0 8px',
   justifyContent: 'space-between',
   cursor: 'pointer',
}

const buttonSubFilterStyle = {
   textTransform: 'none',
}

const subContainerFilter = {
   backgroundColor: 'white',
   border: '1px solid black',
   padding: '10px',
}



const dataHbar = {
   labels: ['Sin disponiblidad', 'Sin Arrendar'],
   datasets: [
      {
         label: 'Productos',
         backgroundColor: ['#36A2EB', '#FF6384'],
         borderColor: 'rgba(255,99,132,1)',
         borderWidth: 1,
         hoverBackgroundColor: 'rgba(255,99,132,0.4)',
         hoverBorderColor: 'rgba(255,99,132,1)',
         data: [80, 20], // Reemplaza esto con los datos reales
      },
   ],
};

// const dataLine = {
//    labels: ['Julio', 'Agosto', 'Setiembre', 'Octubre', 'Noviembre', 'Diciembre'],
//    datasets: [
//       {
//          label: 'Facturación Mensual por Arriendos',
//          data: [22000, 15000, 27000, 25000, 16000, 28000],
//          fill: true,
//          borderColor: 'rgba(75,192,192,1)',
//          lineTension: 0.1,
//       },
//       {
//          label: 'Ingreso Devengado por Arriendos',
//          data: [23000, 24000, 29000, 32000, 35000, 32000],
//          fill: true,
//          borderColor: 'rgba(85,102,102,3)',
//          lineaTension: 0.2
//       }
//    ],
// };

const lastMonthVariation = 10;


export const ArriendosView: React.FC<any> = ({ }): JSX.Element => {

   const totalDeudas = 15000;
   const deudasPromedio = 500;
   const vencimientosProximos = {
      "5 días": 10,
      "30 días": 25,
      "60 días": 15,
   };



   // const data = {
   //    labels: ['Pagados a tiempo', 'Deudas Pendientes'],
   //    datasets: [{
   //       data: [175, 225], // Porcentajes (ajusta según tus datos)
   //       backgroundColor: ['#5cb85c', '#d9534f'], // Colores
   //       hoverBackgroundColor: ['#4cae4c', '#c9302c'], // Colores al pasar el mouse
   //    }],
   // };

   const history = useHistory()
   const userData = readLocalStorage(KEY_USER_DATA);
   const idempresa = userData?.user?.idempresacliente;

   const [dataProducts, setDataProducts] = useState<any>([])
   const [dataProductosBusqueda, setDataProductosBusqueda] = useState<any>([])

   const [dataClients, setDataClients] = useState<any>([])
   const [clientSelected, setClientSelected] = useState<any>(null);

   const [search, setSearch] = useState('');

   const [anchorEl, setAnchorEl] = useState<any>({
      statusArriendo: null,
      dates: null,
      product: null,
      client: null
   })

   const handleClick = (event: React.MouseEvent<HTMLButtonElement>, tipo) => {
      switch (tipo) {
         case 'statusArriendo':
            setAnchorEl({ ...anchorEl, statusArriendo: event.currentTarget });
            return
         case 'dates':
            setAnchorEl({ ...anchorEl, dates: event.currentTarget });
            return
         case 'product':
            setAnchorEl({ ...anchorEl, product: event.currentTarget });
            return
         case 'client':
            setAnchorEl({...anchorEl, client: event.currentTarget})
            return
      }

   };

   const handleClose = () => {
      setAnchorEl({
         statusArriendo: null,
         dates: null,
         product: null,
         client: null
      });
   };

   const [valueFilterDates, setValueFilterDates] = useState<any>({
      start: null,
      end: null
   })

   const [dataArriendos, setDataArriendos] = useState<any>([])
   const [open, setOpen] = useState<boolean>(false);
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'success',
      message: 'Error',
      autoHideDuration: 5000,
   })

   const [perPageData, setPerPageData] = useState<number>(15);
   const [pageData, setPageData] = useState<number>(1)
   const [Total, setTotal] = useState<any>(0)

   const [openModalGarantia, setOpenModalGarantia] = useState<any>(false)
   const [arriendoSelected, setArriendoSelected] = useState<any>(null)

   const [selectedStatus, setSelectedStatus] = useState([]);
   const [selectedProducts, setSelectedProducts] = useState([])
   const [openFilter, setOpenFilter] = useState<any>(false)

   const [datosArriendos, setDatosArriendos] = useState<any>([])
   const [openModalDelete, setOpenModalDelete] = useState<boolean>(false)

   useEffect(() => {
      try {
         getArriendos();
         getProductosArriendos();
         getDashboardDatosGenerales();
         getClientes();
      } catch (e) {
         console.log(e)
      }
   }, [pageData, perPageData])

   useEffect(() => {
      getProductosArriendos(search)
   }, [search])

   useEffect(() => {
      getStockProducts();
   }, [selectedProducts])

   useEffect(() => {
      getArriendosFilter();
   }, [selectedStatus, valueFilterDates, selectedProducts, clientSelected])

   const recuperarData = (data) => {
      switch (data.action) {
         case 'view-detail':
            history.push(`/detalle-arriendos`, data)
            return
         case 'edit':
            return;
         case 'delete':
            setOpenModalDelete(true);
            setArriendoSelected(data)
            return;
         case 'view-garantia':
            setOpenModalGarantia(true);
            setArriendoSelected(data)
            return;
         case 'view-pagos':
            history.push(`/arriendos-pagos`, data)
            return;
      }

   }

   async function getArriendosFilter() {
      setOpen(true);
      const response = await ArriendosService.listarArriendos(selectedStatus, valueFilterDates, selectedProducts, clientSelected?.idcliente, pageData, perPageData);
      if (!!response?.status) {
         setDataArriendos(response?.data)
         setPageData(1)
      } else {
         setSnackBarConfig({ ...snackBarConfig, open: true, message: response?.message, severity: 'error' })
      }
      setOpen(false);
   }

   async function getArriendos() {
      setOpen(true);
      const response = await ArriendosService.listarArriendos(selectedStatus, valueFilterDates, selectedProducts, clientSelected?.idcliente, pageData, perPageData);
      if (!!response?.status) {
         setDataArriendos(response?.data)
      } else {
         setSnackBarConfig({ ...snackBarConfig, open: true, message: response?.message, severity: 'error' })
      }
      setOpen(false);
   }

   async function getDashboardDatosGenerales() {
      setOpen(true);
      const response = await ArriendosService.getDashboardDatosGenerales();
      if (!!response?.status) {
         setDatosArriendos(response?.data)
      } else {
         setSnackBarConfig({ ...snackBarConfig, open: true, message: response?.message, severity: 'error' })
      }
      setOpen(false);
   }

   const getProductosArriendos = async (search = '') => {
      setOpen(true);
      const response = await ArriendosService.listarProductsArriendo(search, idempresa);

      if (!!response?.status) {
         setDataProductosBusqueda(response?.data)
      } else {
         setSnackBarConfig({ ...snackBarConfig, open: true, message: response?.message, severity: 'error' })
      }
      setOpen(false);
   }

   const getClientes = async() => {
      const responseClients = await contactService.getContactByArriendo();
      if (!!responseClients) { setDataClients(responseClients?.data || []); }

   }

   const getStockProducts = async () => {
      setOpen(true);
      const idsArray = selectedProducts.map(product => product.id_producto);
      const jsonEncoded = JSON.stringify(idsArray);
      const response = await ArriendosService.listarStockForProducts(jsonEncoded);
      if (!!response?.status) {
         setDataProducts(response?.data)
      } else {
         setSnackBarConfig({ ...snackBarConfig, open: true, message: response?.message, severity: 'error' })
      }

      setOpen(false);
   }

   const deleteArriendo = async () => {
      const response = await ArriendosService.deleteArriendo(arriendoSelected?.id)
      if (!!response?.status) {
         setSnackBarConfig({ ...snackBarConfig, open: true, message: response?.message, severity: 'success' })
         await getArriendos();
     } else {
         setSnackBarConfig({ ...snackBarConfig, open: true, message: response?.message, severity: 'error' })
     }
     setOpenModalDelete(false)
   }

   const PerPage = async (data) => {
      setPerPageData(data)
      setPageData(1)
   }

   const page = async (data) => {
      setPageData(data + 1)
   }

   const openRegisterArrriendo = () => {
      history.push('/arriendos-registro');
   }

   const handleStatusToggle = (status) => () => {
      console.log(status)
      const currentIndex = selectedStatus.indexOf(status);
      const newSelectedStatus = [...selectedStatus];
      if (currentIndex === -1) {
         newSelectedStatus.push(status);
      } else {
         newSelectedStatus.splice(currentIndex, 1);
      }
      console.log(newSelectedStatus)
      setSelectedStatus(newSelectedStatus);
   };

   const handleProductosToggle = (itemProducto) => {
      const currentIndex = selectedProducts.indexOf(itemProducto);
      const newSelectedProducts = [...selectedProducts];
      if (currentIndex === -1) {
         newSelectedProducts.push(itemProducto);
      } else {
         newSelectedProducts.splice(currentIndex, 1);
      }
      setSelectedProducts(newSelectedProducts);
   }

   const handleDelete = (tipo, item) => {
      if (tipo == 'status') {

      }

      if (tipo == 'product') {

      }
   };

   const formatNumber = (value) => new Intl.NumberFormat().format(value);
   const getAvailabilityStatus = (available) => (
      <Chip
         label={available ? 'Disponible' : 'Arrendado'}
         color={available ? 'success' : 'error'}
         size="small"
      />
   )

   const getAvailabilityBar = (disponibles, arrendados) => {
      const total = disponibles + arrendados;

      return (
         <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
               style={{
                  width: `${(disponibles / total) * 200}%`,
                  height: '20px',
                  backgroundColor: '#4CAF50', // Verde para disponibles
                  borderRadius: '4px 0 0 4px',
               }}
            />
            <div
               style={{
                  width: `${(arrendados / total) * 200}%`,
                  height: '20px',
                  backgroundColor: '#F44336', // Rojo para arrendados
                  borderRadius: '0 4px 4px 0',
               }}
            />
            <span style={{ marginLeft: '5px' }}>{formatNumber(disponibles)}</span>
            <span style={{ marginLeft: '5px' }}>{' / '}</span>
            <span style={{ marginLeft: '5px' }}>{formatNumber(arrendados)}</span>
         </div>
      );
   };

   return (
      <Protected>
         <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
            <Alert
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               severity={snackBarConfig.severity}
               variant="filled"
            >
               {snackBarConfig.message}
            </Alert>
         </Snackbar>
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
         >
            <Grid container
               direction="row"
               justifyContent="center"
               alignItems="center">
               <SpinnerGrow />
               <h1 style={{ marginLeft: '10px' }}><strong>Cargando...</strong></h1>
            </Grid>
         </Backdrop>
         <ConfirmDialog
               open={openModalDelete}
               title={'Eliminar Arriendo'}
               message={'¿Está seguro de eliminar el arriendo?'}
               onConfirm={() => deleteArriendo()}
               onClose={() => setOpenModalDelete(false)}
            />
         <div>
            <Container maxWidth='xl'>
               <Grid container justifyContent='space-between' spacing={3}>
                  <Grid item xs={5} container alignItems="center">
                     <Typography sx={{ color: '#155172', fontWeight: '700', fontSize: '2.8em' }} >Panel de Arriendos</Typography>
                  </Grid>
                  <Grid item xs={4}
                     container
                     direction="row"
                     justifyContent="flex-end"
                     spacing={3}
                     mt={3}
                     alignItems="center">

                     <Button variant='contained'
                        sx={{
                           background: '#E4980D', color: '#fff', width: '300px', padding: '10px', borderRadius: '30px', fontWeight: 600, fontSize: '18px', '&:hover': {
                              bgcolor: '#E4980D'
                           }
                        }}
                        onClick={() => { openRegisterArrriendo() }}>Registrar</Button>
                  </Grid>
               </Grid>
               <Grid container my={5} bgcolor={'#E9E7E3'} padding={5} >
                  <Grid item xs={8} container justifyContent='center'>
                     <Grid item xs={11} alignItems="center" padding={5} my={2} justifyContent="center" bgcolor={'#fff'} borderRadius={5}>
                        <LineBarFacturadorDevengados
                         setOpen={setOpen}
                         setSnackBarConfig={setSnackBarConfig}
                         snackBarConfig={snackBarConfig}
                        />
                     </Grid>
                  </Grid>
                  <Grid item xs={4} container justifyContent='center'>
                     <Grid item xs={11} alignItems="center" padding={5} my={2} justifyContent="center" bgcolor={'#fff'} borderRadius={5}>
                        <Typography fontSize={22} mb={4} color='#155172' textAlign='center' fontWeight={700}> Resumen del mes</Typography>
                        <Typography fontSize={45} color='green' textAlign='center' fontWeight={700} > {datosArriendos?.variabilidad_devengado} %</Typography>
                        <Typography fontSize={20} mb={4} textAlign='center' fontWeight={700} > Variabilidad</Typography>
                        <Typography fontSize={45} color='green' textAlign='center' fontWeight={700} > {datosArriendos?.ingreso_devengado} CLP</Typography>
                        <Typography fontSize={20} mb={4} textAlign='center' fontWeight={700} > Ingreso mensual</Typography>

                     </Grid>
                  </Grid>
                  <Grid item xs={4} container justifyContent='center'>
                     <Grid item xs={11} alignItems="center" padding={5} my={2} justifyContent="center" bgcolor={'#fff'} borderRadius={5}>
                        <DoughnutEstadoGeneral
                        setOpen={setOpen}
                        setSnackBarConfig={setSnackBarConfig}
                        snackBarConfig={snackBarConfig}
                       />
                     </Grid>
                  </Grid>
                  <Grid item xs={8} container justifyContent='center'>
                     <Grid item xs={11} alignItems="center" padding={5} my={2} justifyContent="center" bgcolor={'#fff'} borderRadius={5}>
                        <ExpirationBar
                        setOpen={setOpen}
                        setSnackBarConfig={setSnackBarConfig}
                        snackBarConfig={snackBarConfig}/>
                     </Grid>
                  </Grid>

                  <Grid item xs={4} container justifyContent='center'>
                     <Grid item xs={11} alignItems="center" padding={5} my={2} justifyContent="center" bgcolor={'#fff'} borderRadius={5}>
                        <LiquidPorcentajeCumpliance textGraph={'Cumplimiento de pago'} porcentage={Number(datosArriendos?.cumplimiento_pago)} />

                     </Grid>
                  </Grid>

                  <Grid item xs={4} container justifyContent='center'>
                     <Grid item xs={11} alignItems="center" padding={5} my={2} justifyContent="center" bgcolor={'#fff'} borderRadius={5}>
                        <LiquidPorcentajeCumpliance textGraph={'Productos arrendados'} porcentage={Number(datosArriendos?.porcentaje_productos_arrendados)} />

                     </Grid>
                  </Grid>
                  <Grid item xs={4} container justifyContent='center'>
                     <Grid item xs={11} alignItems="center" padding={5} my={2} justifyContent="center" bgcolor={'#fff'} borderRadius={5}>
                        <BarStatusProducts
                           setOpen={setOpen}
                           setSnackBarConfig={setSnackBarConfig}
                      snackBarConfig={snackBarConfig}/>
                     </Grid>
                  </Grid>

               </Grid>




               <Grid container item xs={12} mb={2} mt={5} bgcolor={'#E9E7E3'} padding={5}>
                  <div style={buttonFilterStyle1} onClick={() => setOpenFilter(!openFilter)}>
                     <FilterListIcon />
                     <Typography fontSize={20}>Filtros</Typography>
                     {openFilter ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </div>
                  {
                     openFilter && (
                        <>
                           <Grid container item xs={12} mt={3}>
                              {/* Primero filtro : Estado de Arriendo */}
                              <Button
                                 id="basic-button"
                                 aria-controls={Boolean(anchorEl && anchorEl.statusArriendo) ? "basic-menu" : undefined}
                                 aria-haspopup="true"
                                 aria-expanded={Boolean(anchorEl && anchorEl.statusArriendo) ? "true" : undefined}
                                 onClick={(e) => handleClick(e, 'statusArriendo')}
                                 endIcon={Boolean(anchorEl && anchorEl.statusArriendo) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                 sx={buttonFilterStyle}
                              >
                                 <Typography sx={{ textTransform: 'none' }}>
                                    Estado de Arriendo
                                 </Typography>
                              </Button>
                              <Popover
                                 id="basic-menu"
                                 anchorEl={anchorEl && anchorEl.statusArriendo}
                                 open={Boolean(anchorEl && anchorEl.statusArriendo)}
                                 onClose={handleClose}
                                 anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                 }}
                                 transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                 }}
                              >
                                 <div style={{ width: '300px' }}>
                                    {
                                       dataStatus.map(status => {
                                          return (
                                             <MenuItem >
                                                <Checkbox
                                                   checked={selectedStatus.includes(status)}
                                                   onChange={handleStatusToggle(status)}
                                                />
                                                {status.name}
                                             </MenuItem>
                                          )
                                       })
                                    }
                                    {/* <MenuItem >
                                       <Checkbox
                                          checked={selectedStatus.includes({id:1, name:"Vigente"})}
                                          onChange={handleStatusToggle({id:1, name:"Vigente"})}
                                       />
                                       Vigente
                                    </MenuItem>
                                    <MenuItem>
                                       <Checkbox
                                          checked={selectedStatus.includes({id:2, name:"Histórico"})}
                                          onChange={handleStatusToggle({id:2, name:"Histórico"})}
                                       />
                                       Histórico
                                    </MenuItem> */}
                                 </div>
                              </Popover>

                              {/* Segundo filtro : Rango de Fechas */}
                              <Button
                                 id="basic-button"
                                 aria-controls={Boolean(anchorEl && anchorEl.dates) ? "basic-menu" : undefined}
                                 aria-haspopup="true"
                                 aria-expanded={Boolean(anchorEl && anchorEl.dates) ? "true" : undefined}
                                 onClick={(e) => handleClick(e, 'dates')}
                                 endIcon={Boolean(anchorEl && anchorEl.dates) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                 sx={buttonFilterStyle}
                              >
                                 <Typography sx={{ textTransform: 'none' }}>
                                    Rango de Fechas
                                 </Typography>
                              </Button>
                              <Popover
                                 id="basic-menu-2"
                                 anchorEl={anchorEl && anchorEl.dates}
                                 open={Boolean(anchorEl && anchorEl.dates)}
                                 onClose={handleClose}
                                 anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                 }}
                                 transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                 }}
                              >
                                 <div style={{ width: '300px', padding: '20px' }}>
                                    <strong > Desde:</strong>
                                    <div style={{ marginBottom: '10px', marginTop: '10px' }}>
                                       <TextField
                                          type="date"
                                          fullWidth
                                          name='start'
                                          value={valueFilterDates?.start}
                                          onChange={(e) => setValueFilterDates({ ...valueFilterDates, start: e.target.value })}
                                       />
                                    </div>
                                    <strong> Hasta:</strong>
                                    <div style={{ marginBottom: '10px', marginTop: '10px' }}>
                                       <TextField
                                          type="date"
                                          fullWidth
                                          name='end'
                                          value={valueFilterDates?.end}
                                          onChange={(e) => setValueFilterDates({ ...valueFilterDates, end: e.target.value })}
                                       />
                                    </div>
                                 </div>

                              </Popover>

                              {/* Tercer filtro : Productos */}
                              <Button
                                 id="basic-button"
                                 aria-controls={Boolean(anchorEl && anchorEl.product) ? "basic-menu" : undefined}
                                 aria-haspopup="true"
                                 aria-expanded={Boolean(anchorEl && anchorEl.product) ? "true" : undefined}
                                 onClick={(e) => handleClick(e, 'product')}
                                 endIcon={Boolean(anchorEl && anchorEl.product) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                 sx={buttonFilterStyle}
                              >
                                 <Typography sx={{ textTransform: 'none' }}>
                                    Producto
                                 </Typography>
                              </Button>
                              <Popover
                                 id="basic-menu-2"
                                 anchorEl={anchorEl && anchorEl.product}
                                 open={Boolean(anchorEl && anchorEl.product)}
                                 onClose={handleClose}
                                 anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                 }}
                                 transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                 }}
                              >
                                 <div style={{ width: '800px', padding: '20px' }}>
                                    <strong > Búsqueda por código o nombre:</strong>
                                    <div style={{ marginBottom: '10px', marginTop: '10px' }}>
                                       <TextField
                                          type="search"
                                          fullWidth
                                          size="small"
                                          onChange={(e) => setSearch(e.target.value)}
                                       />
                                       <div style={{ height: 300 }}>
                                          {
                                             dataProductosBusqueda.length > 0 ? dataProductosBusqueda.map((item) => {
                                                return (
                                                   <MenuItem >
                                                      <Checkbox
                                                         checked={selectedProducts.includes(item)}
                                                         onChange={(e) => handleProductosToggle(item)}
                                                      />
                                                      {item.codigo} {item.nombre_producto}
                                                   </MenuItem>
                                                )

                                             }) :
                                                <div style={{ height: 280, display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#E9E7E3' }}>
                                                   <Typography variant='h5'>No se encontraron resultados.</Typography>
                                                </div>
                                          }
                                       </div>
                                    </div>
                                 </div>

                              </Popover>

                               {/* Cuarto filtro : Clientes */}
                               <Button
                                 id="basic-button"
                                 aria-controls={Boolean(anchorEl && anchorEl.client) ? "basic-menu" : undefined}
                                 aria-haspopup="true"
                                 aria-expanded={Boolean(anchorEl && anchorEl.client) ? "true" : undefined}
                                 onClick={(e) => handleClick(e, 'client')}
                                 endIcon={Boolean(anchorEl && anchorEl.client) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                 sx={buttonFilterStyle}
                              >
                                 <Typography sx={{ textTransform: 'none' }}>
                                    Cliente
                                 </Typography>
                              </Button>
                              <Popover
                                 id="basic-menu-2"
                                 anchorEl={anchorEl && anchorEl.client}
                                 open={Boolean(anchorEl && anchorEl.client)}
                                 onClose={handleClose}
                                 anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                 }}
                                 transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                 }}
                              >
                                 <div style={{ width: '800px', minHeight: '300px', padding: '20px' }}>
                                    <strong > Búsqueda por rut o nombre:</strong>
                                    <Autocomplete
                                            disablePortal
                                            id="combo-box-company"
                                            value={clientSelected}
                                            options={dataClients || []}
                                            getOptionLabel={(option) =>  option.rut_cliente + " " +option.nombre_rz || ""}
                                            isOptionEqualToValue={(option, value) => option?.idcliente === value?.idcliente}
                                            fullWidth
                                            filterOptions={(options, { inputValue }) => {
                                             const filtered = options.filter(option =>
                                                 option.rut_cliente.toLowerCase().includes(inputValue.toLowerCase()) ||
                                                 option.nombre_rz.toLowerCase().includes(inputValue.toLowerCase())
                                             );
                                             return filtered;
                                         }}
                                            renderInput={(params) => <TextField {...params} size="small"
                                            />}
                                            onChange={(e, newValue) => {
                                                setClientSelected(newValue);
                                                handleClose()
                                            }}
                                        />
                                 </div>

                              </Popover>

                           </Grid>
                           <Grid container item xs={12} sx={{ margin: '15px', width: '50px' }}>
                              <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap' }}>
                                 {
                                    selectedStatus.map((status) => {
                                       return (
                                          <Chip label={status.name} onDelete={handleStatusToggle(status)} sx={{ maxWidth: '100%', marginBottom: '5px', fontSize: '18px' }} />
                                       )
                                    })
                                 }
                                 {
                                    !!valueFilterDates.start && !!valueFilterDates.end && <Chip label={` De ${valueFilterDates.start} a ${valueFilterDates.end}`} sx={{ maxWidth: '100%', marginBottom: '5px', fontSize: '18px' }} onDelete={() => setValueFilterDates({ start: null, end: null })} />
                                 }
                                 {
                                    selectedProducts.map((product) => {
                                       return (
                                          <Chip label={`${product.codigo} ${product.nombre_producto}`} sx={{ maxWidth: '100%', marginBottom: '5px', fontSize: '18px' }} onDelete={() => handleProductosToggle(product)} />
                                       )
                                    })
                                 }
                                 {
                                    clientSelected && 
                                          <Chip label={`${clientSelected.rut_cliente} ${clientSelected.nombre_rz}`} sx={{ maxWidth: '100%', marginBottom: '5px', fontSize: '18px' }} onDelete={() => setClientSelected(null)} />
                                 }
                              </Stack>
                           </Grid>
                           <Grid container mt={5} mb={1}>

               </Grid>

                        </>
                     )
                  }


                  <Grid container sx={{ mt: 2 }}>
                  <Typography variant="h4" fontWeight={500}>Arriendos encontrados</Typography>

                  <TableData
                     header={header}
                     data={dataArriendos ? dataArriendos : []}
                     action={dataAction}
                     RecuperarData={recuperarData}
                     total={1000}
                     perPage={PerPage}
                     page={page}
                     perPageData={perPageData}
                  />
               </Grid>
               </Grid>



               {
                  false &&
                  <Grid container item xs={12} mt={5} mb={6}>
                  <Typography variant="h4" fontWeight={500}>Información de los productos seleccionados</Typography>

               </Grid>
               }

               {
                  dataProducts.length > 0 && false &&
                     <TableContainer component={Paper} elevation={3} style={{ borderRadius: '10px', overflow: 'hidden' }}>
                        <Table>
                           <TableHead sx={{ bgcolor: '#3f9bcc' }}>
                              <TableRow>
                                 <TableCell align="center" style={{ fontWeight: 'bold', color: 'white', fontSize: '1.1em' }} >Código</TableCell>
                                 <TableCell align="center" style={{ fontWeight: 'bold', color: 'white', fontSize: '1.1em' }}>Nombre del Producto</TableCell>
                                 <TableCell align="center" style={{ fontWeight: 'bold', color: 'white', fontSize: '1.1em' }}>Stock</TableCell>
                                 <TableCell align="center" style={{ fontWeight: 'bold', color: 'white', fontSize: '1.1em' }}>Disponibles / Arrendados</TableCell>
                                 <TableCell align="center" style={{ fontWeight: 'bold', color: 'white', fontSize: '1.1em' }}>Estado</TableCell>
                              </TableRow>
                           </TableHead>
                           <TableBody>
                              {dataProducts.map((product) => (
                                 <TableRow key={product.id}>
                                    <TableCell align="center">{product.codigo}</TableCell>
                                    <TableCell align="center">{product.nombre_producto}</TableCell>
                                    <TableCell align="center">
                                       <Button onClick={()=>console.log('hola')}>
                                          {formatNumber(product.stock)}
                                       </Button>
                                    </TableCell>
                                    <TableCell align="center">{getAvailabilityBar(formatNumber(product.productos_disponibles), formatNumber(product.productos_arrendados))}</TableCell>
                                    <TableCell align="center">
                                       {getAvailabilityStatus(product.productos_disponibles > 0)}
                                       {product.productos_disponibles > 0 ? <TrendingUpIcon style={{ marginLeft: '5px', color: 'green' }} /> : <TrendingDownIcon style={{ marginLeft: '5px', color: 'red' }} />}
                                    </TableCell>
                                 </TableRow>
                              ))}
                           </TableBody>
                        </Table>
                     </TableContainer>
                     // :
                     // <div style={{ height: 280, display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#E9E7E3' }}>
                     //    <Typography variant='h5'>No se encontraron resultados.</Typography>
                     // </div>
               }

            </Container>
         </div>
         {
            openModalGarantia &&
            <ModalGarantiaArriendo
               open={openModalGarantia}
               setOpen={setOpenModalGarantia}
               data={arriendoSelected}
            />
         }
      </Protected>
   )
};
