import { CustomFormFooter } from "@/components/common/CustomForm/CustomFormFooter";
import { Protected } from "@/components/layout/Protected";
import { ArriendosService } from "@/service/services/Arriendos.service.";
import { contactService } from "@/service/services/Contact.service";
import { productService } from "@/service/services/Product.service";
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { Alert, Autocomplete, Backdrop, Button, Card, CardContent, Checkbox, CircularProgress, Divider, FormControlLabel, FormLabel, Grid, Snackbar, TextField, Typography } from "@mui/material"
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory } from "react-router-dom";
import { ROUTE_ARRIENDOS } from "@/toolbox/constants/route-map";
import { SpinnerGrow } from "@/components/common/Spinner";
import moment from 'moment';
import debounce from 'lodash/debounce';

const dataTipoDocumento = [
    // { id: 1, name: 'Nota de venta' }, 
{ id: 2, name: 'Factura electrónica' }, { id:3, name:'Cotización'}]
const dataTipoAtencion = [{ id: 1, name: 'Mesón' }, { id: 2, name: 'Autoatendible' }]
const dataPaymentWay = [{ id: 1, name: 'Efectivo' }, { id: 2, name: 'Cheque' }, { id: 3, name: 'Transferencia' }, { id: 4, name: '30 DIAS' }, { id: 5, name: 'Redcompra' }]

export const CreateArriendos: React.FC<any> = ({
    isEdit
}): JSX.Element => {

    const history = useHistory();

    const datoUsuario = readLocalStorage(KEY_USER_DATA)
    const rut_empresa = datoUsuario?.user?.rut_empresa;

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'success',
        message: 'Error',
        autoHideDuration: 5000,
    })
    const [open, setOpen] = useState<boolean>(false)
    const [dataArriendo, setDataArriendo] = useState<any>({
        idcliente: '',
        servicesRent: [],
        othersServiceOrProducts: [],
        fecha_arriendo: '',
        precio_dia: '',
        fecha_probable_devolucion: '',
        monto: '',
        observacion: '',
        iniciar_con_facturacion: true,
        pago_garantia: true,
        iniciar_totalidad_facturacion: false,
        registrarpago: false,
        registrarpago_garantia: false,
        marked_pay: false,
        idtipo_doc: 2,
        idpayment_way: '',
        idpayment_way_garantia: '',
        banco_origen: '',
        banco_destino: ''
    });

    const [dataProductsServiceRent, setDataProductsServiceRent] = useState<any>([]);
    const [dataOthersServiceOrProducts, setDataOthersServiceOrProducts] = useState<any>([]);
    const [dataClients, setDataClients] = useState<any>([])
    const [clientSelected, setClientSelected] = useState<any>(null);
    const [clientFacturadorSelected, setClientFacturadorSelected] = useState<any>(null)
    const [paymentImage, setPaymentImage] = useState(null);
    const [paymentImageGarantia, setPaymentImageGarantia] = useState(null);

    const [documento, setDocumento] = useState<any>({});
    const [paymentWay, setPaymentWay] = useState<any>({});
    const [paymentWayGarantia, setPaymentWayGarantia] = useState<any>({});

    const [tipoAtencionDefault, setTipoAtencionDefault] = useState<any>(null)

    const [bancos, setBancos] = useState<any>([]);
    const [bancoSelected, setBancoSelected] = useState<any>(null);
    const [bancosEmpresa, setBancosEmpresa] = useState<any>([]);
    const [bancosEmpresaSelected, setBancosEmpresaSelected] = useState<any>(null);


    useEffect(() => {
        getDataIntial();
        getConfiguracion();
        getBancos();
        getBancosEmpresa();
    }, [])

    const getDataIntial = async () => {
        const responseProductsServices = await productService.getProductService();
        const servicesRent = responseProductsServices?.data?.services.filter((service) => {
            return service.nombre && service.nombre.includes('ARRIENDO');
        })
        setDataProductsServiceRent(servicesRent || []);

        const responseOtherProducts = await productService.getDataProducts();
        setDataOthersServiceOrProducts(responseOtherProducts?.data?.products);

        // const responseClients = await contactService.getContactAll();
        // if (!!responseClients) { setDataClients(responseClients?.data?.clients_all || []); }
    };

    const getBancos = async () => {
        const bancos = await ArriendosService.getBancos();
        setBancos(bancos.data);
    }

    const getBancosEmpresa = async () => {
        const bancos = await ArriendosService.getBancosEmpresa();
        setBancosEmpresa(bancos.data);
    }

    
    
    const getConfiguracion = async () => {
        const resp: any = await ArriendosService.getConfiguracion(rut_empresa);
        if (!!resp) {
            const findTipoAtencion = dataTipoAtencion.find(item => item.id == resp?.data?.idtipo_atencion);
            const findTipoDocumento = dataTipoDocumento.find(item => item?.id == resp?.data?.idtipo_documento_facturacion);

            setTipoAtencionDefault(findTipoAtencion);
            setDocumento(findTipoDocumento);
        }
    }


    const validateForm = (values) => {
        let errors: any = {};
        if (!values.fecha_arriendo) {
            errors.fecha_arriendo = 'Campo requerido';
        }else {
            const today = moment();
            const selectedDate = moment(values.fecha_arriendo);
            const minDate = moment().subtract(185, 'days');

            if (selectedDate.isBefore(minDate, 'day')) {
                errors.fecha_arriendo = 'La fecha debe ser al menos 59 días antes de hoy';
            }
          }

          console.log(errors)
          console.log(values)
        if (!values.idcliente) {
            console.log(errors)
            errors.idcliente = 'Campo requerido';
        }
        // Validaciones para el campo productos
        if (!values.servicesRent || values.servicesRent.length === 0) {
            errors.servicesRent = 'Seleccione almenos un servicio';
        } else {
            // Si hay productos, validamos cada campo dentro de productos
            errors.servicesRent = values.servicesRent.map((serviceRent, index) => {
                const serviceRentErrors: any = {};
                // Validaciones específicas para cada campo dentro de serviceRents
                if (!serviceRent.cantidad) {
                    serviceRentErrors.cantidad = 'Campo requerido';
                }
                if (!serviceRent.precioDiario) {
                    serviceRentErrors.precioDiario = 'Campo requerido';
                }
                if (!serviceRent.precioMensual) {
                    serviceRentErrors.precioMensual = 'Campo requerido';
                }
                if (serviceRent.garantia < 0) {
                    serviceRentErrors.garantia = 'Campo requerido';
                }
                if (!serviceRent.observacion_admin) {
                    serviceRentErrors.observacion_admin = 'Campo requerido';
                }
                if (!serviceRent.fechaDevolucion && !!serviceRent.knowsReturnDate)  {
                    serviceRentErrors.fechaDevolucion = 'Campo requerido';
                }
                // Devolver undefined si no hay errores específicos para este serviceRent
                return Object.keys(serviceRentErrors).length === 0 ? undefined : serviceRentErrors;
            });

            // Filtrar productos sin errores (undefined)
            errors.servicesRent = errors.servicesRent.filter((error) => error !== undefined);
        }
        console.log(values)
        if(values.othersServiceOrProducts.length > 0 ){
            errors.othersServiceOrProducts = values.othersServiceOrProducts.map((serviceOrProduct, index) => {
                const othersServiceOrProductsErrors:any = {};
                if(!serviceOrProduct.cantidad) {
                    othersServiceOrProductsErrors.cantidad = 'Campo requerido';
                }
                if(!serviceOrProduct.newPrecio) {
                    othersServiceOrProductsErrors.newPrecio = 'Campo requerido';
                }
                return Object.keys(othersServiceOrProductsErrors).length === 0 ? undefined : othersServiceOrProductsErrors;
            });
            errors.othersServiceOrProducts = errors.othersServiceOrProducts.filter((error) => error !== undefined);
        }
        if (!values.idpayment_way) {
            errors.idpayment_way = 'Campo requerido';
        }
        if (!!values.pago_garantia && !values.idpayment_way_garantia) {
            errors.idpayment_way_garantia = 'Campo requerido';
        }
        if (!!values.registrarpago && !values.cod_pago) {
            errors.cod_pago = 'Campo requerido';
        }
        if (!!values.marked_pay && values.idpayment_way != 1 && values.idpayment_way != 5 && !values.banco_origen){
            errors.banco_origen = 'Campo requerido';
        }
        if (!!values.marked_pay && values.idpayment_way != 1 && values.idpayment_way != 5 && !values.banco_destino){
            errors.banco_destino = 'Campo requerido';
        }
        if (!!values.pago_garantia && values.registrarpago_garantia && !values.cod_pago_garantia) {
            errors.cod_pago_garantia = 'Campo requerido';
        }
        if (!values.idtipo_doc) {
            errors.idtipo_doc = 'Campo requerido';
        }
        if (!values.observacion) {
            errors.observacion = 'Campo requerido';
        }
       
        if (!!errors.servicesRent && errors.servicesRent.length == 0) {
            console.log(errors.servicesRent);
            delete errors.servicesRent;
        }

        if (!!errors.othersServiceOrProducts && errors.othersServiceOrProducts.length == 0) {
            console.log(errors.othersServiceOrProducts);
            delete errors.othersServiceOrProducts;
        }
        console.log(errors)

        return errors;
    };

    const obtenerMontoGarantia = (descripcion) => {
        // Utilizando una expresión regular para buscar el monto después de "GARANTIA ARRIENDO $"
        const regex = /GARANTIA ARRIENDO\s*\$?(\d{1,3}(?:,\d{3})*(?:\.\d{1,3})?)/;
        const match = descripcion.match(regex);
        if (match) {
          const montoGarantia = match[1].replace(/[^\d,]/g, ''); // Elimina caracteres no numéricos, excepto la coma
          return montoGarantia;
        } else {
          return ''; 
        }
      };
     
    const onSubmit = async (values) => {
        setOpen(true)
        const formData = new FormData();

        formData.append('fecha_arriendo', values.fecha_arriendo)
        formData.append('idcliente', values?.idcliente);
        if (clientFacturadorSelected?.idcliente) {
            formData.append('idcliente_facturacion', clientFacturadorSelected?.idcliente);
        }
        formData.append('services', JSON.stringify(values.servicesRent));
        if(values.othersServiceOrProducts.length > 0){
            formData.append('othersServiceOrProducts', JSON.stringify(values.othersServiceOrProducts) )
        }
        formData.append('documentoEmision', JSON.stringify(documento));
        formData.append('rut_empresa', rut_empresa);
        formData.append('observacion', values.observacion);
        formData.append('iniciar_con_facturacion', values.iniciar_con_facturacion);
        formData.append('iniciar_totalidad_facturacion', values.iniciar_totalidad_facturacion);
        formData.append('pago_garantia', values.pago_garantia);
        formData.append('registrarpago', values.registrarpago);
        formData.append('registrarpago_garantia', values.registrarpago_garantia);
        formData.append('marked_pay', values.marked_pay);
        formData.append('idpayment_way', values.idpayment_way);
        formData.append('idpayment_way_garantia', values.idpayment_way_garantia);

        
        if(!!values.registrarpago && !!values.cod_pago){
            formData.append('cod_pago', values.cod_pago);
        }
        if(!!values.registrarpago && !!values.paymentImage){
            formData.append('paymentImage', values.paymentImage);
        }
        if(!!values.registrarpago_garantia && !!values.cod_pago_garantia){
            formData.append('cod_pago_garantia', values.cod_pago_garantia);
        }
        if(!!values.registrarpago_garantia && !!values.paymentImageGarantia){
            formData.append('paymentImageGarantia', values.paymentImageGarantia);
        }
        if(!!values.marked_pay && !!values.banco_origen){
            formData.append('banco_origen', values.banco_origen);
        }
        if(!!values.marked_pay && !!values.banco_destino){
            formData.append('banco_destino', values.banco_destino);
        }
        const registerArriendo = await ArriendosService.registerArriendo(formData);
        if (!!registerArriendo?.status) {
            setSnackBarConfig({ ...snackBarConfig, open: true, message: registerArriendo?.message, severity: 'success' })

            history.push('/arriendos');

        } else {
            setSnackBarConfig({ ...snackBarConfig, open: true, message: registerArriendo?.message, severity: 'error' })
        }
        setOpen(false)
       
    }

    const onCancel = async () => {

    }

    const totalGarantia = (values) => {
        let total = 0;
        values.servicesRent.map((serviceRent, index) => {
            let garantia = 0
            let cantidad = 0
            if (!!serviceRent.garantia) {
                garantia = serviceRent.garantia
            }
            if (!!serviceRent.cantidad) {
                cantidad = serviceRent.cantidad
            }
            total = total + garantia * cantidad
        })
        return total
    }

    const totalPrimerDiaArriendo = (values) => {
        let total = 0;
        values.servicesRent.map((serviceRent, index) => {
            let precioDiario = 0
            let cantidad = 0
            if (!!serviceRent.precioDiario) {
                precioDiario = serviceRent.precioDiario
            }
            if (!!serviceRent.cantidad) {
                cantidad = serviceRent.cantidad
            }
            total = total + precioDiario * cantidad
        })
        return total
    }

    const totalArriendo = (values) => {
        let total = 0;
        values.servicesRent.map((serviceRent, index) => {
            let precioDiario = 0
            let cantidad = 0
            let diasDeAlquiler = 1;
            if (!!serviceRent.precioDiario) {
                precioDiario = serviceRent.precioDiario
            }
            if (!!serviceRent.cantidad) {
                cantidad = serviceRent.cantidad
            }
            if (!!serviceRent.fechaDevolucion) {
                const fechaProbableDevolucion = moment(serviceRent.fechaDevolucion);
                const fechaInicio = moment(values.fecha_arriendo)
                const hoy = moment();
                diasDeAlquiler = fechaProbableDevolucion.diff(fechaInicio, 'days'); 
                diasDeAlquiler = diasDeAlquiler < 1 ? 1 : diasDeAlquiler; 
            }
            total = total + precioDiario * cantidad * diasDeAlquiler;
        })
        return total
    }

    const totalOtherServicesOrProducts = (values) => {
        let total = 0;
        values.othersServiceOrProducts.map((serviceOrProduct, index) => {
            let newPrecio = 0
            let cantidad = 0
            if (!!serviceOrProduct.newPrecio) {
                newPrecio = serviceOrProduct.newPrecio
            }
            if (!!serviceOrProduct.cantidad) {
                cantidad = serviceOrProduct.cantidad
            }
            total = total + newPrecio * cantidad
        })
        return total
    }

    const totalPagar = (values) => {
        let subtotal = 0 ;
        let iva = 0;
        let total = 0;
        if(values.servicesRent.length > 0 && values.iniciar_con_facturacion ) {
            subtotal = totalPrimerDiaArriendo(values)
        }
        if(values.servicesRent.length > 0 && values.iniciar_totalidad_facturacion ) {
            subtotal = totalArriendo(values)
        }
        if(values.othersServiceOrProducts.length > 0) {
            subtotal = subtotal + totalOtherServicesOrProducts(values)
        }
        if(subtotal > 0){
            iva = Math.ceil(subtotal * 0.19)
            total = subtotal + iva
        }
        return {
            subtotal,
            iva,
            total
        }
    }

    //fetched de clientes
    const [clientOptions, setClientOptions] = useState([]);
    // const [clientSelected, setClientSelected] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);

    // Debounce the search function to limit API calls
    const debouncedFetchClients = debounce(async(query) => {
        if (query.length > 2) {  // Optionally, you can set a minimum character limit for search
            console.log(query)
            const responseClients = await contactService.getContactAll(query);
            if (!!responseClients) { setClientOptions(responseClients?.data?.clients_all || []); }

        } else {
            setClientOptions([]);
        }
    }, 500); // Wait for 300ms of inactivity

    // UseEffect to trigger the API call when the searchTerm changes
    useEffect(() => {
        debouncedFetchClients(searchTerm);

        // Cleanup the debounce on component unmount
        return () => {
            debouncedFetchClients.cancel();
            setClientOptions([]);
        };
    }, [searchTerm]);

    const body = (
        <Grid>
            <Grid container xs={12} mx={2}>
                <Button
                    startIcon={<ArrowBackIcon />}
                    variant='text'
                    onClick={() => { history.push(ROUTE_ARRIENDOS) }}
                    sx={{
                        background: '#E4980D', color: '#fff', '&:hover': {
                            bgcolor: '#E4980D'
                        }
                    }}
                >
                    Volver
                </Button>
            </Grid>
            <Grid item xs={12} container alignItems="center" mx={2}>
                <Typography sx={{ color: '#155172', fontWeight: '700', fontSize: '2.8em' }} >Registro de Arriendos: Complete los detalles del arriendo para su gestión interna.</Typography>
            </Grid>
            <Formik initialValues={dataArriendo} enableReinitialize validate={(values) => validateForm(values)} onSubmit={onSubmit}>
                {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <Grid container sx={{ padding: '20px' }}>
                                <Grid item container xs={12} spacing={2} sx={{ mt: 2 }}>
                                <Grid item xs={6}>
                                        <FormLabel>Inicio de Arriendo:</FormLabel>
                                        <TextField
                                            id="fecha_arriendo"
                                            type="date"
                                            name="fecha_arriendo"
                                            fullWidth
                                            size="small"
                                            value={values.fecha_arriendo}
                                            onChange={handleChange}
                                            error={errors.fecha_arriendo && touched.fecha_arriendo ? true : false}
                                            helperText={errors.fecha_arriendo && touched.fecha_arriendo ? errors.fecha_arriendo : ''}
                                        />
                                    </Grid>
                                    {/* <Grid item xs={6}>
                                        <FormLabel>Cliente:</FormLabel>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-company"
                                            value={clientSelected}
                                            options={dataClients || []}
                                            getOptionLabel={(option) =>  option.rut_cliente + " " +option.nombre_rz || ""}
                                            isOptionEqualToValue={(option, value) => option?.idcliente === value?.idcliente}
                                            fullWidth
                                            renderInput={(params) => <TextField {...params} size="small"
                                                error={errors.idcliente && touched.idcliente ? true : false}
                                            />}
                                            onChange={(e, newValue) => {
                                                setClientSelected(newValue);
                                                !!newValue && setFieldValue('idcliente', newValue.idcliente)
                                            }}
                                        />
                                    </Grid> */}
                                    <Grid item xs={6}>
                                        <FormLabel>Cliente:</FormLabel>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-company"
                                            value={clientSelected}
                                            options={clientOptions}
                                            loading={loading}
                                            getOptionLabel={(option) => option.rut_cliente + " " + option.nombre_rz || ""}
                                            isOptionEqualToValue={(option, value) => option?.idcliente === value?.idcliente}
                                            fullWidth
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    size="small"
                                                    error={errors.idcliente && touched.idcliente ? true : false}
                                                    onChange={(e) => setSearchTerm(e.target.value)}  // Update search term on input change
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </>
                                                        ),
                                                    }}
                                                />
                                            )}
                                            onChange={(e, newValue) => {
                                                setClientSelected(newValue);
                                                if (!!newValue) {
                                                    setFieldValue('idcliente', newValue.idcliente);
                                                } else {
                                                    // Si no hay un cliente seleccionado, limpiar el campo 'idcliente'
                                                    setFieldValue('idcliente', '');
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormLabel>¿Desea facturar el primer dia?</FormLabel>
                                        <Checkbox
                                            checked={values.iniciar_con_facturacion}
                                            onChange={() => {
                                                if(!values.iniciar_con_facturacion){
                                                    setFieldValue('iniciar_totalidad_facturacion', !!values.iniciar_con_facturacion);
                                                }
                                                setFieldValue('iniciar_con_facturacion', !values.iniciar_con_facturacion);
                                               
                                            }}
                                            color="primary"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormLabel>¿Desea facturar la totalidad de dias?</FormLabel>
                                        <Checkbox
                                            checked={values.iniciar_totalidad_facturacion}
                                            onChange={() => {
                                                if(!values.iniciar_totalidad_facturacion){
                                                    setFieldValue('iniciar_con_facturacion', !!values.iniciar_totalidad_facturacion);
                                                }
                                                setFieldValue('iniciar_totalidad_facturacion', !values.iniciar_totalidad_facturacion);        
                                            }}
                                            color="primary"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormLabel>¿Ha dejado pago de garantia?</FormLabel>
                                        <Checkbox
                                            checked={values.pago_garantia}
                                            onChange={() => {
                                                setFieldValue('pago_garantia', !values.pago_garantia);     
                                            }}
                                            color="primary"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormLabel>Servicios Arriendos:</FormLabel>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-products"
                                            options={dataProductsServiceRent || []}
                                            getOptionLabel={(option: any) => option?.nombre || ""}
                                            isOptionEqualToValue={(option, value) =>
                                                option?.id === value?.id
                                            }
                                            multiple
                                            fullWidth
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    size="small"
                                                    error={
                                                        errors.servicesRent && errors.servicesRent.length != 0 && touched.servicesRent ? true : false
                                                    }
                                                />
                                            )}
                                            onChange={(e, newValues) => {
                                                const servicesRentMap = values.servicesRent.reduce((acc, item) => {
                                                    acc[item.id] = item;
                                                    return acc;
                                                }, {});
                                            
                                                const newArray = newValues.map((serviceRent) => {
                                                    const findService = servicesRentMap[serviceRent.id];
                                                    const precioDiario = findService ? findService.precioDiario : serviceRent.precio;
                                                    const precioMensual = findService ? findService.precioMensual : serviceRent.precio * 30;
                                                    const observacion_admin = findService ? findService.observacion_admin : '';
                                                    const knowsReturnDate = findService ? findService.knowsReturnDate : false;
                                                    const fecha_probable_devolucion = findService ? findService.fecha_probable_devolucion : '';
                                            
                                                    return {
                                                        ...serviceRent,
                                                        cantidad: findService ? findService.cantidad : 1,
                                                        precioDiario,
                                                        precioMensual,
                                                        garantia: obtenerMontoGarantia(serviceRent.nombre),
                                                        observacion_admin,
                                                        knowsReturnDate,
                                                        fecha_probable_devolucion
                                                    };
                                                });
                                            
                                                setFieldValue('servicesRent', newArray);
                                            }}                                            
                                        />
                                    </Grid>
                                    {
                                        values.servicesRent.length > 0 &&
                                        <>
                                            {values.pago_garantia &&
                                                <Grid item xs={3}>
                                                    <FormLabel>Total Garantia:</FormLabel>
                                                    <TextField
                                                        disabled
                                                        value={totalGarantia(values)}
                                                        type="number"
                                                        fullWidth
                                                        size="small"
                                                    />
                                                </Grid>
                                            }
                                            {
                                                values.iniciar_con_facturacion && 
                                                <Grid item xs={3}>
                                                    <FormLabel>Total a pagar (1er dia arriendo):</FormLabel>
                                                    <TextField
                                                        disabled
                                                        value={totalPrimerDiaArriendo(values)}
                                                        type="number"
                                                        fullWidth
                                                        size="small"
                                                    />
                                                </Grid>
                                            }
                                            {
                                                 values.iniciar_totalidad_facturacion && 
                                                 <Grid item xs={3}>
                                                     <FormLabel>Total a pagar (arriendo):</FormLabel>
                                                     <TextField
                                                         disabled
                                                         value={totalArriendo(values)}
                                                         type="number"
                                                         fullWidth
                                                         size="small"
                                                     />
                                                 </Grid>
                                            }
                                            <Grid item xs={12} sx={{ height: '450px', overflowY: 'scroll', padding: 5, marginY: 5 }}>
                                                {(values.servicesRent || []).map((serviceRent, index) => (
                                                    <Card key={index} style={{ marginTop: 16 }}>
                                                        <CardContent style={{ backgroundColor: '#f0f0f0', padding: 16, borderRadius: 8 }}>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12}>
                                                                    <Typography variant="h6">
                                                                        Detalle del servicio "{serviceRent.nombre}" 
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <FormLabel>Cantidad:</FormLabel>
                                                                    <TextField
                                                                        type="number"
                                                                        name={`servicesRent[${index}].cantidad`}
                                                                        value={values.servicesRent[index].cantidad}
                                                                        onChange={handleChange}
                                                                        fullWidth
                                                                        size="small"
                                                                        error={Boolean(errors.servicesRent && errors.servicesRent[index] && errors.servicesRent[index].cantidad)}
                                                                        helperText={errors.servicesRent && errors.servicesRent[index] && errors.servicesRent[index].cantidad}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <FormLabel>Precio diario:</FormLabel>
                                                                    <TextField
                                                                        type="number"
                                                                        name={`servicesRent[${index}].precioDiario`}
                                                                        value={values.servicesRent[index].precioDiario}
                                                                        onChange={(e) => {
                                                                            const newValue = e.target.value
                                                                            setFieldValue(`servicesRent[${index}].precioDiario`, newValue)
                                                                            let newPrecioMensual = parseInt(newValue)*30;
                                                                            setFieldValue(`servicesRent[${index}].precioMensual`, newPrecioMensual)
                                                                        }}
                                                                        fullWidth
                                                                        size="small"
                                                                        error={Boolean(errors.servicesRent && errors.servicesRent[index] && errors.servicesRent[index].precioDiario)}
                                                                        helperText={errors.servicesRent && errors.servicesRent[index] && errors.servicesRent[index].precioDiario}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <FormLabel>Precio mensual (30 dias):</FormLabel>
                                                                    <TextField
                                                                        type="number"
                                                                        name={`servicesRent[${index}].precioMensual`}
                                                                        value={values.servicesRent[index].precioMensual}
                                                                        onChange={handleChange}
                                                                        fullWidth
                                                                        disabled
                                                                        size="small"
                                                                        error={Boolean(errors.servicesRent && errors.servicesRent[index] && errors.servicesRent[index].precioMensual)}
                                                                        helperText={errors.servicesRent && errors.servicesRent[index] && errors.servicesRent[index].precioMensual}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <FormLabel>Monto Garantia:</FormLabel>
                                                                    <TextField
                                                                        type="number"
                                                                        name={`servicesRent[${index}].garantia`}
                                                                        value={values.servicesRent[index].garantia}
                                                                        onChange={handleChange}
                                                                        fullWidth
                                                                        size="small"
                                                                        error={Boolean(errors.servicesRent && errors.servicesRent[index] && errors.servicesRent[index].garantia)}
                                                                        helperText={errors.servicesRent && errors.servicesRent[index] && errors.servicesRent[index].garantia}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <FormLabel>Observaciones del Producto:</FormLabel>
                                                                    <TextField
                                                                        type="text"
                                                                        name={`servicesRent[${index}].observacion_admin`}
                                                                        value={values.servicesRent[index].observacion_admin}
                                                                        onChange={handleChange}
                                                                        fullWidth
                                                                        size="small"
                                                                        error={Boolean(errors.servicesRent && errors.servicesRent[index] && errors.servicesRent[index].observacion_admin)}
                                                                        helperText={errors.servicesRent && errors.servicesRent[index] && errors.servicesRent[index].observacion_admin}
                                                                    />  
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    {
                                                                        moment(values.fecha_arriendo).isBefore(moment(), 'day') ?
                                                                            <FormLabel> {"Indicar fecha de cierre de primera factura"} </FormLabel> :
                                                                            <FormLabel> {"¿Sabe la posible fecha de devolución?"} </FormLabel>
                                                                    }
                                                                    <Checkbox
                                                                        checked={values.servicesRent[index].knowsReturnDate}
                                                                        onChange={() => {
                                                                            const newServicesRent = [...values.servicesRent];
                                                                            newServicesRent[index].knowsReturnDate = !newServicesRent[index].knowsReturnDate;
                                                                            setFieldValue('servicesRent', newServicesRent);
                                                                            // Si el checkbox está desmarcado, establecer fechaDevolucion en null para ese producto
                                                                            if (!newServicesRent[index].knowsReturnDate) {
                                                                                setFieldValue(`servicesRent[${index}].fechaDevolucion`, null);
                                                                            }
                                                                        }}
                                                                        color="primary"
                                                                    />
                                                                </Grid>
                                                                {!!values.servicesRent[index].knowsReturnDate && (
                                                                    <Grid item xs={12}>
                                                                        {
                                                                            moment(values.fecha_arriendo).isBefore(moment(), 'day') ?
                                                                                <FormLabel> {"Fecha de cierre de primera factura?"} </FormLabel> :
                                                                                <FormLabel> {"Fecha Probable Devolución:"} </FormLabel>
                                                                        }
                                                                        <TextField
                                                                            type="date"
                                                                            name={`servicesRent[${index}].fechaDevolucion`}
                                                                            value={values.servicesRent[index].fechaDevolucion}
                                                                            onChange={handleChange}
                                                                            fullWidth
                                                                            size="small"
                                                                            error={Boolean(errors.servicesRent && errors.servicesRent[index] && errors.servicesRent[index].fechaDevolucion)}
                                                                            helperText={errors.servicesRent && errors.servicesRent[index] && errors.servicesRent[index].fechaDevolucion}
                                                                            disabled={!values.servicesRent[index].knowsReturnDate}
                                                                        />
                                                                    </Grid>
                                                                )}

                                                            </Grid>
                                                        </CardContent>
                                                    </Card>
                                                ))}
                                            </Grid>
                                        </>


                                    }

                                    <Grid item xs={12}>
                                        <FormLabel>Otros servicios/productos a facturar:</FormLabel>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-products"
                                            options={dataOthersServiceOrProducts || []}
                                            getOptionLabel={(option: any) => option?.nombre || ""}
                                            isOptionEqualToValue={(option, value) =>
                                                option?.id === value?.id
                                            }
                                            multiple
                                            fullWidth
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    size="small"
                                                    error={
                                                        errors.othersServiceOrProducts && errors.othersServiceOrProducts.length != 0 && touched.othersServiceOrProducts ? true : false
                                                    }
                                                />
                                            )}
                                            onChange={(e, newValues) => {

                                                const OtherServicesOrProductMap = values.othersServiceOrProducts.reduce((acc, item) => {
                                                    acc[item.id] = item;
                                                    return acc;
                                                }, {});
                                            
                                                const newArray = newValues.map((serviceOrProduct) => {
                                                    const findServiceOrProduct = OtherServicesOrProductMap[serviceOrProduct.id];
                                                    const newPrecio = findServiceOrProduct ? findServiceOrProduct.precio : serviceOrProduct.precio;
                                            
                                                    return {
                                                        ...serviceOrProduct,
                                                        cantidad: findServiceOrProduct ? findServiceOrProduct.cantidad : 1,
                                                        newPrecio
                                                    };
                                                });
                                            
                                                setFieldValue('othersServiceOrProducts', newArray);

                                              }}
                                        />
                                    </Grid>

                                    {
                                        values.othersServiceOrProducts.length > 0 && 
                                        <>
                                        <Grid item xs={3}>
                                            <FormLabel>Total otros servicios/productos:</FormLabel>
                                            <TextField
                                                disabled
                                                value={totalOtherServicesOrProducts(values)}
                                                type="number"
                                                fullWidth
                                                size="small"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sx={{ height: values.othersServiceOrProducts.length == 1 ? '180px':'380px', overflowY: 'scroll', padding: 5, marginY: 5 }}>
                                                {(values.othersServiceOrProducts || []).map((serviceOrProduct, index) => (
                                                    <Card key={index} style={{ marginTop: 16 }}>
                                                        <CardContent style={{ backgroundColor: '#f0f0f0', padding: 16, borderRadius: 8 }}>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12}>
                                                                    <Typography variant="h6">
                                                                        Detalle de "{serviceOrProduct.nombre}" 
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <FormLabel>Cantidad:</FormLabel>
                                                                    <TextField
                                                                        type="number"
                                                                        name={`othersServiceOrProducts[${index}].cantidad`}
                                                                        value={values.othersServiceOrProducts[index].cantidad}
                                                                        onChange={handleChange}
                                                                        fullWidth
                                                                        size="small"
                                                                        error={Boolean(errors.othersServiceOrProducts && errors.othersServiceOrProducts[index] && errors.othersServiceOrProducts[index].cantidad)}
                                                                        helperText={errors.othersServiceOrProducts && errors.othersServiceOrProducts[index] && errors.othersServiceOrProducts[index].cantidad}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <FormLabel>Precio:</FormLabel>
                                                                    <TextField
                                                                        type="number"
                                                                        name={`othersServiceOrProducts[${index}].newPrecio`}
                                                                        value={values.othersServiceOrProducts[index].newPrecio}
                                                                        onChange={handleChange}
                                                                        fullWidth
                                                                        size="small"
                                                                        error={Boolean(errors.othersServiceOrProducts && errors.othersServiceOrProducts[index] && errors.othersServiceOrProducts[index].newPrecio)}
                                                                        helperText={errors.othersServiceOrProducts && errors.othersServiceOrProducts[index] && errors.othersServiceOrProducts[index].newPrecio}
                                                                    />
                                                                </Grid>
                                                                
                                                            </Grid>
                                                        </CardContent>
                                                    </Card>
                                                ))}
                                            </Grid>
                                        </>  
                                    }

                                    <Grid item xs={3}>
                                        <FormLabel>Subtotal:</FormLabel>
                                        <TextField
                                            disabled
                                            value={totalPagar(values)?.subtotal}
                                            type="number"
                                            fullWidth
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormLabel>IVA:</FormLabel>
                                        <TextField
                                            disabled
                                            value={totalPagar(values)?.iva}
                                            type="number"
                                            fullWidth
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormLabel>Total:</FormLabel>
                                        <TextField
                                            disabled
                                            value={totalPagar(values)?.total}
                                            type="number"
                                            fullWidth
                                            size="small"
                                        />
                                    </Grid>
                                    { values.pago_garantia &&
                                        <Grid item xs={12}>
                                            <FormLabel>¿Qué forma de pago utilizo para la garantia? </FormLabel>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-company"
                                                value={paymentWayGarantia}
                                                options={dataPaymentWay || []}
                                                getOptionLabel={(option) => option.name || ""}
                                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} size="small"
                                                    error={errors.idpayment_way_garantia && touched.idpayment_way_garantia ? true : false}
                                                />}
                                                onChange={(e, newValue) => {
                                                    setPaymentWayGarantia(newValue);
                                                    !!newValue && setFieldValue('idpayment_way_garantia', newValue.id)
                                                }}
                                            />
                                        </Grid>
                                    }
                                    <Grid item xs={12}>
                                        <FormLabel>¿Qué forma de pago utilizará para la facturación? </FormLabel>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-company"
                                            value={paymentWay}
                                            options={dataPaymentWay || []}
                                            getOptionLabel={(option) => option.name || ""}
                                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                            fullWidth
                                            renderInput={(params) => <TextField {...params} size="small"
                                                error={errors.idpayment_way && touched.idpayment_way ? true : false}
                                            />}
                                            onChange={(e, newValue) => {
                                                setPaymentWay(newValue);
                                                newValue?.name == '30 DIAS' && setFieldValue('marked_pay', false)

                                                newValue?.name == 'Cheque' && setFieldValue('registrarpago', true)
                                                newValue?.name != 'Cheque' && setFieldValue('registrarpago', false)

                                                newValue?.name == 'Efectivo' && setFieldValue('banco_origen', '') && setBancoSelected(null)
                                                newValue?.name == 'Efectivo' && setFieldValue('banco_destino', '') && setBancosEmpresaSelected(null)

                                                newValue?.name == 'Redcompra' && setFieldValue('banco_origen', '') && setBancoSelected(null)
                                                newValue?.name == 'Redcompra' && setFieldValue('banco_destino', '') && setBancosEmpresaSelected(null)

                                                !!newValue && setFieldValue('idpayment_way', newValue.id)
                                            }}
                                        />
                                    </Grid>

                                    

                                    <Grid item xs={12}>
                                        <FormLabel>Marcar la primera factura como pagado</FormLabel>
                                        <Checkbox
                                            checked={values.marked_pay}
                                            disabled={paymentWay?.name == '30 DIAS'}
                                            onChange={() => {
                                                setFieldValue('marked_pay', !values.marked_pay);
                                            }}
                                            color="primary"
                                        />
                                    </Grid>

                                    { values.marked_pay && paymentWay?.name !== 'Efectivo' && paymentWay?.name !== 'Redcompra' &&

                                        <>
                                            <Grid item xs={12}>
                                                <FormLabel>¿Cual es el banco de origen? </FormLabel>
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-company"
                                                    value={bancoSelected}
                                                    options={bancos || []}
                                                    getOptionLabel={(option) => option.nombre || ""}
                                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                    fullWidth
                                                    renderInput={(params) => <TextField {...params} size="small"
                                                        error={errors.banco_origen && touched.banco_origen ? true : false}
                                                    />}
                                                    onChange={(e, newValue) => {
                                                        setBancoSelected(newValue);
                                                        setFieldValue('banco_origen', newValue?.nombre || '')
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormLabel>¿Cual es el banco de destino? </FormLabel>
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-company"
                                                    value={bancosEmpresaSelected}
                                                    options={bancosEmpresa || []}
                                                    getOptionLabel={(option) => option.nombre || ""}
                                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                    fullWidth
                                                    renderInput={(params) => <TextField {...params} size="small"
                                                        error={errors.banco_destino && touched.banco_destino ? true : false}
                                                    />}
                                                    onChange={(e, newValue) => {
                                                        setBancosEmpresaSelected(newValue);
                                                        setFieldValue('banco_destino', newValue?.id || '')
                                                    }}
                                                />
                                            </Grid>
                                            
                                        </>
                                    }

                                    {values.pago_garantia && <Grid item xs={12}>
                                        <FormLabel>¿Registrar datos de pago de la garantia?</FormLabel>
                                        <Checkbox
                                            checked={values.registrarpago_garantia}
                                            onChange={() => {
                                                setFieldValue('registrarpago_garantia', !values.registrarpago_garantia);
                                                // Si el checkbox se desmarca, establecer fecha_probable_devolucion a null
                                                if (!values.registrarpago_garantia) {
                                                    setFieldValue('cod_pago_garantia', null);
                                                    setPaymentImageGarantia(null)
                                                }
                                            }}
                                            color="primary"
                                        />
                                    </Grid>}
                                    {
                                        !!values.registrarpago_garantia &&
                                        <>
                                            <Grid item xs={12}>
                                                <FormLabel>Código de Pago:</FormLabel>
                                                <TextField
                                                    id="cod_pago_garantia"
                                                    type="text"
                                                    name="cod_pago_garantia"
                                                    fullWidth
                                                    size="small"
                                                    value={values.cod_pago_garantia}
                                                    onChange={handleChange}
                                                    error={errors.cod_pago_garantia && touched.cod_pago_garantia ? true : false}
                                                    helperText={errors.cod_pago_garantia && touched.cod_pago_garantia ? errors.cod_pago_garantia : ''}
                                                />

                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormLabel>Imagen de Pago:</FormLabel>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(e) => { setPaymentImageGarantia(e.target.files[0]); setFieldValue('paymentImageGarantia', e.target.files[0]) }}
                                                    style={{ marginBottom: '10px' }}
                                                />
                                            </Grid>
                                        </>
                                    }
                                    <Grid item xs={12}>
                                        <FormLabel>¿Registrar datos de pago de la primera factura?</FormLabel>
                                        <Checkbox
                                            checked={values.registrarpago}
                                            disabled={paymentWay?.name == 'Cheque'}
                                            onChange={() => {
                                                setFieldValue('registrarpago', !values.registrarpago);
                                                // Si el checkbox se desmarca, establecer fecha_probable_devolucion a null
                                                if (!values.registrarpago) {
                                                    setFieldValue('cod_pago', null);
                                                    setPaymentImage(null)
                                                }
                                            }}
                                            color="primary"
                                        />
                                    </Grid>
                                    {
                                        !!values.registrarpago &&
                                        <>
                                            <Grid item xs={12}>
                                                <FormLabel>Código de Pago:</FormLabel>
                                                <TextField
                                                    id="cod_pago"
                                                    type="text"
                                                    name="cod_pago"
                                                    fullWidth
                                                    size="small"
                                                    value={values.cod_pago}
                                                    onChange={handleChange}
                                                    error={errors.cod_pago && touched.cod_pago ? true : false}
                                                    helperText={errors.cod_pago && touched.cod_pago ? errors.cod_pago : ''}
                                                />

                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormLabel>Imagen de Pago:</FormLabel>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(e) => { setPaymentImage(e.target.files[0]); setFieldValue('paymentImage', e.target.files[0]) }}
                                                    style={{ marginBottom: '10px' }}
                                                />
                                            </Grid>
                                        </>
                                    }
                                    <Grid item xs={12}>
                                        <FormLabel>¿Desea emitir guía de despacho?</FormLabel>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={values.emitirGuiaDespacho}
                                                    onChange={() => {
                                                        setFieldValue('emitirGuiaDespacho', true);
                                                    }}
                                                    color="primary"
                                                />
                                            }
                                            label="Sí"
                                            labelPlacement="start" // Puedes ajustar la posición de la etiqueta
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={!values.emitirGuiaDespacho}
                                                    onChange={() => {
                                                        setFieldValue('emitirGuiaDespacho', false);
                                                    }}
                                                    color="primary"
                                                />
                                            }
                                            label="No"
                                            labelPlacement="start" // Puedes ajustar la posición de la etiqueta
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormLabel>¿Desea facturar los pagos a otro cliente?</FormLabel>
                                        <Checkbox
                                            checked={values.otroCliente}
                                            onChange={() => {
                                                setFieldValue('otroCliente', !values.otroCliente);
                                                // Si el checkbox se desmarca, establecer fecha_probable_devolucion a null
                                                if (!values.otroCliente) {
                                                    // setFieldValue('cod_pago', null);
                                                    // setPaymentImage(null)
                                                }
                                            }}
                                            color="primary"
                                        />
                                    </Grid>

                                    {!!values.otroCliente && <Grid item xs={12}>
                                        <FormLabel>Cliente a Facturar:</FormLabel>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-company"
                                            value={clientFacturadorSelected}
                                            options={dataClients || []}
                                            getOptionLabel={(option) => option.nombre_rz || ""}
                                            isOptionEqualToValue={(option, value) => option?.idcliente === value?.idcliente}
                                            fullWidth
                                            renderInput={(params) => <TextField {...params} size="small"
                                                error={errors.idclienteFactura && touched.idclienteFactura ? true : false}
                                            />}
                                            onChange={(e, newValue) => {
                                                setClientFacturadorSelected(newValue);
                                                !!newValue && setFieldValue('idclienteFactura', newValue.idcliente)
                                            }}
                                        />
                                    </Grid>}

                                    <Grid item xs={12}>
                                        <FormLabel>¿Qué documento utilizará para el cobro mensual? </FormLabel>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-company"
                                            value={documento}
                                            options={dataTipoDocumento || []}
                                            getOptionLabel={(option) => option.name || ""}
                                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                            fullWidth
                                            renderInput={(params) => <TextField {...params} size="small"
                                                error={errors.idtipo_doc && touched.idtipo_doc ? true : false}
                                            />}
                                            onChange={(e, newValue) => {
                                                setDocumento(newValue);
                                                !!newValue && setFieldValue('idtipo_doc', newValue.id)
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormLabel>Observaciones:</FormLabel>
                                        <TextField
                                            id="observacion"
                                            type="text"
                                            multiline
                                            name="observacion"
                                            fullWidth
                                            size="small"
                                            value={values.observacion}
                                            onChange={handleChange}
                                            error={errors.observacion && touched.observacion ? true : false}
                                            helperText={errors.observacion && touched.observacion ? errors.observacion : ''}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Divider />
                            <CustomFormFooter
                                buttonType="submit"
                                confirmText={!isEdit ? 'Guardar' : 'Actualizar'}
                                cancelText={"Cancelar"}
                                onConfirm={handleSubmit}
                                onCancel={onCancel}
                            />
                        </form>
                    )
                }}
            </Formik>

        </Grid>
    )
    return (
        <Protected>
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                    severity={snackBarConfig.severity}
                    variant="filled"
                >
                    {snackBarConfig.message}
                </Alert>
            </Snackbar>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center">
                    <SpinnerGrow />
                    <h1 style={{ marginLeft: '10px' }}><strong>Cargando...</strong></h1>
                </Grid>
            </Backdrop>
            {body}
        </Protected>
    );
}